import hacker_rank_1 from './assets/img/hacker-rank-1.png';
import hacker_rank_2 from './assets/img/hacker-rank-2.png';
import hacker_rank_3 from './assets/img/hacker-rank-3.png';

import { Image } from 'react-bootstrap';

function Hackerrank() {
    return (
        <div>
            <p>
                <b>
                    Profile: <a href='https://www.hackerrank.com/stuart_marshall'>Stuart Marshall</a>
                </b>
            </p>
            <Image fluid src={hacker_rank_1} alt='Hackerrank submissions 1' />
            <Image fluid src={hacker_rank_2} alt='Hackerrank submissions 2' />
            <Image fluid src={hacker_rank_3} alt='Hackerrank submissions 3' />
        </div>
    );
}

export default Hackerrank;