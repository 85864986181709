import './App.css';
import './bootstrap.min.css';

function Resume() {
  return (
    <div >
      <p>
        <a href='https://www.linkedin.com/in/thestuartmarshall/'>My LinkedIn profile</a>
      </p>
      <h3>
        Summary
      </h3>
      <p>
        Hands-on, experienced, productive, and flexible developer and technical lead of large scale, sophisticated software systems. Designed, developed, deployed, and operated successful software products and services at AWS, Microsoft, and in startup environments. Worked in and managed world class teams in software services, database engines, payment processing, information retrieval, distributed systems, machine learning, robotics, gaming, and streaming media.
        </p><p>
        Demonstrated track record as a high-level software engineer and as a manager of software teams. Recruited, mentored, and grew high performing engineers. Frequently sought out as a trusted and capable mentor. Founded and grew teams from scratch as well as joined and managed large, existing teams.
        </p><p>
        Designed systems and collaborated with stakeholders to guide and determine product vision. Translated product vision to technical design. Worked closely with product management and solution architects to integrate business and customer needs with engineering execution. Talked frequently with customers to assist them and to understand their problems, goals, requirements, and requests.
      </p>
      <h3>
        Experience
      </h3>
      <b>
        Founder, Developer, bThere.ai, 2019 – current
      </b>
      <p>
        Founded and built bThere.ai, a SaaS B2B startup providing remote monitoring and control services to robot developers. Our software is helping robot developers every day on multiple continents.
      </p><p>
        Designed, built, and operated a stack of technologies from robot through cloud to web client. Built C++ agents that run on Linux robots, integrated with ROS, and connected to cloud services. Built multiple services running on AWS: a custom Java WebRTC SFU/MCU media server leveraging GStreamer, a NodeJS authentication and control plane service in front of a DynamoDB database, and a NodeJS signaling and telemetry server. Built a web console using React and WebRTC to enable secure, low-latency, and easy monitoring and control of robots. Collected and relayed robot metrics and operational data. Streamed hardware compressed h264 from robots to web pages with very low latency. Deployed and operated multiple regions and multiple environments. Ensured very high availability. Built in robust observability. Assisted customers with technical challenges, many outside the scope of our service.
      </p><p>
        On the business side: Found and talked with prospective customers, built product roadmaps, wrote business plans, and built financial models. Constructed and carried out marketing and sales plans and onboarded customers. Recruited and managed multi-disciplinary team. Analyzed strategic environment and business opportunities. Created and presented pitch decks. Collaborated with co-founders on all of the above. Operated in a low information, low resource environment while quickly making and executing on business plans.
      </p>
      <b>
        Founder, Developer, bThere.tech, Dec 2020 – April 2021
      </b>
      <p>
        Designed, built, and deployed prototype consumer devices using edge CV for person detection and activity analytics. Leveraged TensorFlow for CV on device and AWS IoT Core and Lamba in the cloud. Designed and developed statistical algorithms to analyze behavior patterns and identify anomalies. Extended bThere.ai React web console to present data on activity of seniors. Read and incorporated academic research on senior monitoring and computer vision. Investigated options for multi-signal analytics. Compared and contrasted multiple object detection models using offline datasets. Explored options for identifying, diagnosing, and addressing edge case detection failures.
      </p><p>
        Investigated pivot of bThere.ai to the senior care market to enable remote monitoring of seniors so that they can safely age in place. Identified unmet customer needs and new options enabled by technology inflections. Interviewed potential customers, scoped market size, wrote business plan. Recruited and onboarded beta testers, conducted beta test interviews, tracked beta test results, fed learnings back into product. Conducted test marketing with A/B testing and CAC analysis.
      </p>

      <b>
        Developer of Robotics Technology, Independent, 2015 – 2018
      </b>
      <p>
        Designed and developed software and hardware for telepresence robots. Investigated telepresence market and prototyping business opportunities.
      </p><p>
        Built multiple robots, from small household size to larger sidewalk size. Wrote a high performance, multithreaded server for to allow clients anywhere in the world to control robots and to receive high-volume, low-latency telemetry and audio/video. Designed and built a custom system similar to WebRTC before WebRTC was widely available. Leveraged RTP over UDP with h264 hardware encoding. Encrypted data end to end using 128-bit AES in CBC mode, with initial key exchange via 2048-bit RSA public and private keys. Built protocols and servers to negotiate connections and easily traverse firewalls. Wrote Android and Mac/PC clients that provided two-way audio/video with the robots and full motor control. On the business side, investigated business models and researched current market participants. Explored market opportunities and met with potential customers and collaborators. Built and tested prototypes for different scenarios. Drove robots remotely, with low latency control from thousands of miles away.
      </p>

      <b>
        Senior Software Development Manager, Amazon Web Services, 2011 – 2015
      </b>
      <p>
        Managed development teams that built and operated DynamoDB, a very high-scale, low-latency, highly available database service. DynamoDB is one of the most successful AWS services. It grew from launch in January 2012 to quickly become the core database system for thousands of customers, including almost all new Amazon services. Helped grow the org from 25 engineers up to 100 engineers three years later. Guided teams to design and deliver many features, talked with hundreds of customers, and maintained extremely high operational excellence. Managed and mentored front-line managers. Collaborated with dev managers and with AWS senior management on DynamoDB long-term strategy. Participated in AWS-wide v-teams concentrating on best practices in data storage.
      </p>

      <b>
        Senior Development Lead, Microsoft, 2001 – 2011
      </b>
      <p>
        Designed, developed, and operated large-scale services. Led development teams. Hired and managed developers.
      </p><p>
        Helped build a common platform of billing and subscription management for the online services of Office, Xbox, Windows, MSN, and many other groups. Led the team that designed and built the payment processing components of the service. Our software managed settlement of over a billion dollars of financial transactions every year. We processed a multitude of different payment methods in many different countries, including credit cards, direct debit, phone carrier billing, bank transfer, convenience store payments and premium SMS. Our services operated at very high scale, with extreme accuracy and fault tolerance, and complied with many business and regulatory requirements. We integrated with dozens of internal product teams and dozens of external vendors. My teams also worked on engineering and service efficiency, including refactoring a monolithic system into a modern service-oriented architecture.
      </p><p>
        Led the Bing Shopping team that provided web crawl and content ingestion. Built and operated software that found and parsed product reviews to help enrich Bing commercial searches. Leveraged and extended academic research to build innovative methods for finding and extracting structured and semi-structured data from web pages. Our software crawled hundreds of web sites, processed terabytes of data in a large-scale map-reduce system, and fed millions of product reviews into the Bing indexes. Our solutions significantly improved customer experience for searches with commercial intent. Our core technologies were subsequently incorporated into the core Bing data pipeline for use across all Bing segments.
      </p>

      <b>
        Director of Technology, WON.net (A division of Sierra On-Line), 1999 – 2000
        <br/>
        Lead Engineer and System Architect, WON.net, 1997 – 1999
      </b>
      <p>
        Led a team that designed, built and operated Internet services for online gaming.  Won.net was the online services subsidiary of Sierra On-Line, which was one of the largest game developers and publishers. Sierra had game studios all over the country developing a wide variety of games, including casual card games, first person shooters, real-time strategy, etc. All of those teams needed and wanted to provide online gameplay. My team built and operated a set of services that the game teams could leverage. We provided software as a service long before AWS. Our services included game directory services, chat services, user profile services, firewall routing services, authentication services, and more. We provided pure backend services with well-developed protocols as well as client libraries and, when appropriate, windows GUI clients. Our customers included triple-A games such as Half-Life and Homeworld.
      </p><p>
        Started the group with a white-paper submission to our CEO. Built the team from the ground up. I was hands-on with design and development of the code. Visited game developers around the country to learn their needs and help them integrate solutions. Oversaw operation of our services, including rollout, troubleshooting, and monitoring. Participated as a core member of the leadership team for our subsidiary, helping craft business strategy and roadmap. We successfully helped many game development teams and provided services for millions of game players.
      </p>

      <b>
        Lead Internet Engineer, Sierra Home, 1997
      </b>
      <p>
        Provided vision and planning of Internet strategy for Sierra Home productivity software. Helped to build new Internet services team. Designed and developed Internet technologies. Worked with product teams to design and implement good network related customer experiences. Performed due diligence on corporate acquisitions.
      </p>

      <b>
        Senior Software Engineer, Sierra On-Line, 1996 – 1997
      <br/>
        Software Engineer, Sierra On-Line / Bright-star Technology, 1994 – 1996
      </b>
      <p>
        Worked as a leading member of a team of developers, artists and designers to develop educational games. Designed and wrote core game engine, game code and development tools using C++ on Windows and Macintosh. Collaborated with designers on prototyping, developing and refining the games to achieve a great, fun customer experience. Adhered to tight schedule, wrote solid high-performance code and shipped on time for simultaneous release on five platforms and three languages.
      </p>
      <h3>
        Technologies and Skills
      </h3>
      <ul>
        <li>Computer science</li>
        <ul>
          <li>Cloud computing, web services, distributed systems, multi-tier architecture, information retrieval</li>
          <li>Machine learning</li>
          <li>IoT</li>
          <li>Robotics</li>
          <li>NoSQL database engines</li>
          <li>Client-server architectures</li>
          <li>Network programming (sockets, multi-threaded socket engines, TCP and UDP)</li>
          <li>Object oriented software design</li>
          <li>Large scale relational database design</li>
          <li>Game development</li>
        </ul>
        <li>Languages</li>
        <ul>
          <li>Java programming for services, Android clients, Swing, and JavaFX clients</li>
          <li>Python</li>
          <li>JavaScript (NodeJS and React)</li>
          <li>C# and the .Net Framework</li>
          <li>C/C++</li>
        </ul>
        <li>Technologies</li>
        <ul>
          <li>ROS 1 (robot operating system)</li>
          <li>AWS – DynamoDB, EC2, S3, IoT Core, Timestream, IAM, Lambda</li>
          <li>NodeJS and Express</li>
          <li>React, Redux, Recharts</li>
          <li>RTP over UDP with h264 and AAC</li>
          <li>WebRTC, both client integration and SFU and MCU servers</li>
          <li>GStreamer</li>
          <li>Basic hardware hacking with motors, motor controllers, h-bridges, power systems, cameras</li>
          <li>Android development</li>
          <li>Windows programming: Win32, .Net, Silverlight, WPF</li>
          <li>Linux</li>
          <li>Jupyter Notebook</li>
          <li>TensorFlow and TensorFlow Lite</li>
          <li>SQL Server and T-SQL</li>
        </ul>
        <li>Other</li>
        <ul>
          <li>Software project management</li>
          <li>Scrum / agile</li>
        </ul>
      </ul>
              <h3>
        Education
      </h3>
      <p>
        MS Business focused on Entrepreneurship, 2020, University of Washington. Graduated top of class<br />
        Mentoring of UW Machine Learning classification course, 2017 – 2019, Coursera<br />
        DeepLearning.AI TensorFlow Developer Certification, Coursera – half completed<br />
        UW Machine Learning, 4 Course Specialization, 2017, Coursera<br />
        BS Mechanical Engineering, 1993, University of Washington<br />
        BA International Studies, 1993, University of Washington<br />
      </p>
      <h3>
        Patents
      </h3>
      <p>
        Recommending provisioned throughput capacity for generating a secondary index for an online table – Patent number 10,146,814, Dec 4, 2018
        Distributed system capacity dial-up – Patent number 9,996,573, June 12, 2018<br />
        Shadowed throughput provisioning – Patent number 9,602,590, Mar 21, 2017<br />
        Consistent query of local indexes – Patent number 9,576,038, Feb 21, 2017<br />
        Conditional master election in distributed databases – Patent number 9,569,513, Feb 14, 2017<br />
        Equitable distribution of excess shared-resource throughput capacity – Patent number 9,553,821, Jan 24, 2017<br />
        Burst-mode admission control using token buckets – Patent number 9,471,393, Oct 18, 2016<br />
        Database system providing skew metrics across a key space – Patent number 9,384,227, Jul 5, 2016<br />
        Range query capacity allocation – Patent number 9,330,158, May 3, 2016<br />
        Token sharing mechanisms for burst-mode operations – Patent number 9,218,221, Dec 22, 2015<br />
        Configurable-capacity time-series tables – Patent number 9,128,965, Sep 8, 2015<br />
        Storage device selection for database partition replicas – Patent number 9,053,167, Jun 9, 2015<br />
        Account related task processing – Patent number 7,949,575, May 24, 2011<br />
        Classifying knowledge aging in emails using Naive Bayes Classifier – Patent number 7,672,912, Mar 2, 2010<br />
        Framework for adding billing payment types – Patent number 7,591,424, Sep 22, 2009<br />
        Handling Payment Receipts with a Receipt Store – Patent number 8,788,350, Jun 13, 2008<br />

      </p>
    </div>
  );
}

export default Resume;
