import sm_lilac from './assets/img/stuart_lilac.jpg';
import { Row, Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';

function Home() {
    return (
        <div>
            <Row>
                <Col>
                    <p>I'm Stuart Marshall.<br/> This is my personal, dev oriented website.</p>
                    <p>See menu at left for pages about recent work.</p>
                    <p>I'm a Seattle native, a graduate of the UW, I have a family, I'm a techie, and I've worked in tech all my career.</p>
                    <p>This site was built with React and is hosted on Github.</p>
                </Col>
                <Col>
                    <Image fluid src={sm_lilac} alt='Stuart Marshall' />
                </Col>

            </Row>

        </div>

    );
}

export default Home;