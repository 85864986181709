import sm_bthere_2021 from './assets/img/stuart-marshall-bthere-2021-contributions.png';
import sm_bthere_2020 from './assets/img/stuart-marshall-bthere-2020-contributions.png';
import sm_bthere_2019 from './assets/img/stuart-marshall-bthere-2019-contributions.png';
import sm_2021 from './assets/img/stuart-marshall-2021-contributions.png';
import sm_2019 from './assets/img/stuart-marshall-2019-contributions.png';
import sm_2018 from './assets/img/stuart-marshall-2018-contributions.png';
import sm_2017 from './assets/img/stuart-marshall-2017-contributions.png';
import sm_2016 from './assets/img/stuart-marshall-2016-contributions.png';
import sm_2015 from './assets/img/stuart-marshall-2015-contributions.png';

import { Image } from 'react-bootstrap';

function Github() {
    return (
        <div>
            <p>
                <b>
                    Work profile: <a href='https://github.com/stuart-marshall-bthere'>stuart-marshall-bthere</a>
                </b>
            </p>
            <p>
                Repo summary
            </p>
            <ul>
                <li>bThere signaling server: Node.js server</li>
                <li>bThere media server: Java WebRTC MCU media server</li>
                <li>bThere control plane: Node.js server in front of DynamoDB</li>
                <li>bThere console: React single page app for remote control of robots</li>
                <li>bThere robot client: C++ ROS node</li>
                <li>bThere room monitor: Python IoT device service</li>
                <li>Various other utility repos</li>
            </ul>
            <Image fluid src={sm_bthere_2021} alt='Stuart Marshall bThere 2021 commits'/>
            <Image fluid src={sm_bthere_2020} alt='Stuart Marshall bThere 2020 commits'/>
            <Image fluid src={sm_bthere_2019} alt='Stuart Marshall bThere 2019 commits'/>
            <p className='pt-5'>
                <b>
                    Personal profile <a href='https://github.com/stuart-marshall'>stuart-marshall</a>
                </b>
            </p>
            <p>
                Repo summary
            </p>
            <ul>
                <li>Personal website: React single page app (this site)</li>
                <li>Robot server: combined Java MCU media server and signaling server</li>
                <li>Robot desktop client: JavaFX desktop for remote control of robots</li>
                <li>Robot mobile client: Java Android app for remote control of robots</li>
                <li>Robot client: Python service running on the robot</li>
                <li>Various other utility repos</li>
            </ul>
            <Image fluid src={sm_2021} alt='Stuart Marshall 2021 commits'/>
            <Image fluid src={sm_2019} alt='Stuart Marshall 2019 commits'/>
            <Image fluid src={sm_2018} alt='Stuart Marshall 2018 commits'/>
            <Image fluid src={sm_2017} alt='Stuart Marshall 2017 commits'/>
            <Image fluid src={sm_2016} alt='Stuart Marshall 2016 commits'/>
            <Image fluid src={sm_2015} alt='Stuart Marshall 2015 commits'/>
        </div>

    );
}

export default Github;