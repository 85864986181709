import './App.css';
import './bootstrap.min.css';
import Home from './Home.js';
import Resume from './Resume.js';
import Github from './Github.js';
import Hackerrank from './Hackerrank.js';
import BthereDotAI from './BthereDotAI.js';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import React from 'react';

class App extends React.Component {

  constructor() {
    super();
    this.state = {
      content: 'Home'
    }
  }

  navigate(event) {
    if (event.target && event.target.id) {
      this.setState({content: event.target.id});
    }
  }

  render() {
    console.info(this.state.content);
    return (
      <>
        <div className="App">
          <header className="App-header">          
          <p>
            Stuart Marshall
          </p>
          </header>
        </div>
        <Container>
          <Row className='pt-3'>
            <Col xl={2} lg={2} md={2} sm={2}>
              <ListGroup variant="flush">
                <ListGroup.Item action id='Home' onClick={this.navigate.bind(this)}>
                  Home
                </ListGroup.Item>
                <ListGroup.Item action id='Resume' onClick={this.navigate.bind(this)}>
                  Resume
                </ListGroup.Item>
                <ListGroup.Item action id='BthereDotAI' onClick={this.navigate.bind(this)}>
                  bThere.ai
                </ListGroup.Item>
                <ListGroup.Item action id='Github' onClick={this.navigate.bind(this)}>
                  Github
                </ListGroup.Item>
                <ListGroup.Item action id='Hackerrank' onClick={this.navigate.bind(this)}>
                  Hackerrank
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col >
              {this.state.content === 'Home' && <Home />}
              {this.state.content === 'Resume' && <Resume />}
              {this.state.content === 'Github' && <Github />}
              {this.state.content === 'Hackerrank' && <Hackerrank />}
              {this.state.content === 'BthereDotAI' && <BthereDotAI />}
            </Col>
            <Col xl={2} lg={2} md={2} sm={2}/>
          </Row>
        </Container>
      </>
    );
  }

}

export default App;
