import { Row, Col } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import big_robot_isometric_and_above from './assets/img/big_robot_isometric_and_above.jpg'
import big_robot_and_console from './assets/img/big_robot_and_console.jpg';
import bthere_system_architecture_v2 from './assets/img/bthere_system_architecture_v2.jpg';
import remote_control_0 from './assets/img/remote_control_0.png';
import admin_control_1 from './assets/img/admin_control_1.png';
import remote_control_1 from './assets/img/remote_control_1.png';
import bthere_logo from './assets/img/robot-icon-medium-png.png';

function BthereDotAI() {
    return (
        <div>
            <Row>
                <Col>
                <Row>
                    <Col>
                    bThere.ai was my startup venture from early 2019 to late 2020.
                    </Col>
                    <Col>
                    <Image width="100" src={bthere_logo}/>
                    </Col>
                </Row>
                <Row>
                    <p>
                    </p>
                    <p>
                        We provided a service for remote monitoring and control of wheeled semi-autonomous robots.
                        </p><p>
                        Robots in semi-controlled environments, like sidewalks and streets, must be remotely monitored and occassionally controlled by humans
                        </p><p>
                        This is a common pattern in the industry and applies to delivery robots, autonomous cars, farm robots, street sweepers, and more.
                        </p><p>
                        Our software could be installed in minutes on a ROS based robot. Developers could then connect to their robot over the network
                        from the bThere web console. Our software would stream video and telemetry from the robot and control messages from the console to the robot.
                        We also provided secure shell to the robot from the console. Our software automatically traversed firewalls, encrypted everything,
                        and worked with low latency on wifi and mobile networks.
                    </p><p>
                        I founded bThere.ai with Matt Wenger. The full team was about a half dozen folks.
                    </p>
                    <p>
                        Our <a href='https://bthere.ai'>bThere.ai website</a> is still live. Check it out.
                    </p>
                    <p>
                        The system had
                    </p>
                    <ul>
                        <li>C++ clients on the ROS robots</li>
                        <li>A web console written with React and using WebRTC</li>
                        <li>A database to keep track of accounts, passwords, robots, permissions</li>
                        <li>A control plane Node.js service in front of the database</li>
                        <li>A signaling Node.js service to facilitate communication and coordination between the console and the robots</li>
                        <li>A java media server to relay and transform WebRTC video streams</li>
                        <li>Servers running in AWS Ohio and Mumbai</li>
                        <li>A React based marketing website</li>
                    </ul>
                    <p> Matt and I wrote all of the code, except for the marketing website.</p>
                    <Image fluid src={bthere_system_architecture_v2} alt='bThere.ai system architecture' />
                    </Row>
                                    </Col>
                <Col>
                    <Image fluid src={big_robot_isometric_and_above} alt='bThere.ai large test robot' />
                    <p>Our large test robot. This robot operated on cell phone networks, had about five cameras, and could drive for hours on city sidewalks.</p>
                    <Image fluid src={big_robot_and_console} alt='bThere.ai large test robot and console' />
                    <p>The robot could be controlled via a console web page. The operator could view live, low-latency video feeds. Clicking on the main image allowed remote driving.</p>
                </Col>
            </Row>
            <Row>
            <Image src={remote_control_0} />
            <p>Driver's perspective to a robot on a mobile connection</p>
            <Image src={remote_control_1} />
            <p>Driver's perspective with multiple cameras</p>
            <Image src={admin_control_1} />
            <p>Multiple active shell connections to the robot</p>
            </Row>
        </div>
    );
}

export default BthereDotAI;
